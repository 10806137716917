<script>
import feather from 'feather-icons';
//import '@/assets/js/script.js';
import jQuery from 'jquery';
const $ = jQuery;
import axios  from 'axios';
import Cookies from 'js-cookie';
import { RouterView } from "vue-router";


import MobilefixMenu from './MobilefixMenu/MobilefixMenu.vue';

import HeaderNav from './HeaderNav/HeaderNav.vue';
import FooterView from './Footer/FooterView.vue';
//import { ModelSelect } from 'vue-search-select'


// Use $ as an alias for jQuery


export default {
  components: {
    MobilefixMenu,
    HeaderNav,
    FooterView,
    RouterView,
   // ModelSelect
  },
   data() {
    return {
        FrontendData: [],
        selectItems: [
        { id: 1, label: 'Option 1' },
        { id: 2, label: 'Option 2' },
        // Add more options as needed
      ],
      selectedCountry: { value: "", text: ""},
      language:null,
      FrontendDatacookie: [],
      FrontendImage: [],
      FrontendImagecookie: [],
      fullPage: null,
    };
  },
   computed: {
    CountryOptions() {
            return this.selectItems.map(item => ({
                value: item.id,
                text: item.label,
            }));
        },
     
  },
  watch: {
        cookieFrontenddata(FrontendData){
            Cookies.set("CookiesFrontenddata", FrontendData, { expires: 6 });
            window.location.reload();

        }
    },
  methods: {
    selectedData(){
        const selectedCountryOptions = this.CountryOptions.find(option => option.value === 2);

        // console.log('selectedBusinessOption:', selectedBusinessOption);
        if (selectedCountryOptions) {
            // Set the selectedBusiness to the found option
            this.selectedCountry = selectedCountryOptions;
        } else {
            // If the option is not found, you can handle it as needed (e.g., display an error message)
            console.error('Selected Country option not found');
        }
    },
    updateData() {
      feather.replace();
    },
    handleCategoryList() {
        const contentwidth = window.innerWidth;
        if (contentwidth < 767) {
            const categoryNames = document.querySelectorAll('.onhover-category-list .category-name');
            
            categoryNames.forEach((categoryName) => {
            const span = document.createElement('span');
            span.className = 'according-menu';
            span.textContent = '+';
            categoryName.appendChild(span);
            
            categoryName.addEventListener('click', () => {
                categoryNames.forEach((name) => {
                name.classList.remove('active');
                const existingSpan = name.querySelector('.according-menu');
                if (existingSpan) {
                    name.removeChild(existingSpan);
                    const newSpan = document.createElement('span');
                    newSpan.className = 'according-menu';
                    newSpan.textContent = '+';
                    name.appendChild(newSpan);
                }
                });

                const onhoverCategoryBox = categoryName.nextElementSibling;

                if (onhoverCategoryBox.style.display === 'none' || onhoverCategoryBox.offsetHeight === 0) {
                categoryName.classList.add('active');
                categoryName.removeChild(span);
                const newSpan = document.createElement('span');
                newSpan.className = 'according-menu';
                newSpan.textContent = '-';
                categoryName.appendChild(newSpan);
                onhoverCategoryBox.style.display = 'block';
                } else {
                categoryName.removeChild(span);
                const newSpan = document.createElement('span');
                newSpan.className = 'according-menu';
                newSpan.textContent = '+';
                categoryName.appendChild(newSpan);
                onhoverCategoryBox.style.display = 'none';
                }
            });
            });

            const accordionBoxes = document.querySelectorAll('.accordion-box');
            accordionBoxes.forEach(box => box.style.display = 'none');
        }
    },
    async fetchFrontendData(language){
        try {
                const response = await axios.get(`frontenddata?${language}`); // Replace with your API endpoint
                this.FrontendData = response.data;
                
                const frontDataString = JSON.stringify(this.FrontendData);
                Cookies.set("CookiesFrontenddata", frontDataString, { expires: 6 });

                // CookiesFrontenddata = Cookies.get('CookiesFrontenddata');
                // if (jsonString) {
                //      console.log('data',JSON.parse(jsonString));
                // }

                // console.log(Cookies.get("CookiesFrontenddata"));
            } catch (error) {
                console.error('Error fetching Frontend data:', error);
            }
    },
    async fetchCookieSettingData(){

        // let loader = this.$loading.show({
        //             // Optional parameters
        //             container: this.fullPage ? null : this.$refs.formContainer,
        //             canCancel: true,
        //             onCancel: this.onCancel,
        //         });
        try {
                const response = await axios.get(`Home/Main/fetchFontCookiesetting`); // Replace with your API endpoint
                // console.log('cookie', response.data);
                if(response.data.length > 0){
                    var cookiedata = response.data;
                    cookiedata.forEach((cookieName) => {
                        if (Cookies.get(cookieName)) {
                            Cookies.remove(cookieName);  // Remove the cookie if it exists
                            //console.log(`Cookie ${cookieName} has been removed`);
                        }
                        if (localStorage.getItem(cookieName)) {
                            localStorage.removeItem(cookieName);
                        }
                    });
                }
              //  loader.hide();
        } catch (error) {
          //  loader.hide();
            console.error('Error fetching Frontend data:', error);
        }

        if(Cookies.get("CookiesFrontenddata")){
            const  jsonString = Cookies.get('CookiesFrontenddata');
            this.FrontendDatacookie =JSON.parse(jsonString);
            // console.log(this.FrontendDatacookie);    
        }else{
            this.fetchFrontendData(this.language);
        }
    }, 

  },
  mounted() {
    if (Cookies.get("CookiesLanguageCode")) {
            this.language = Cookies.get("CookiesLanguageCode");
    } else {
            this.language = 'en';
    }

    
    this.fetchCookieSettingData();
    this.handleCategoryList();
    window.addEventListener('resize', this.handleCategoryList);
    feather.replace();
    this.selectedData();
   
   
  

(function ($) {
    "use strict";
    /*=====================
    01. Image to background js
    ==========================*/
    $(".bg-top").parent().addClass("b-top");
    $(".bg-bottom").parent().addClass("b-bottom");
    $(".bg-center").parent().addClass("b-center");
    $(".bg-left").parent().addClass("b-left");
    $(".bg-right").parent().addClass("b-right");
    $(".bg_size_content").parent().addClass("b_size_content");
    $(".bg-img").parent().addClass("bg-size");
    $(".bg-img.blur-up").parent().addClass("blur-up lazyload");
    $(".bg-img").each(function () {
        var el = $(this),
            src = el.attr("src"),
            parent = el.parent();

        parent.css({
            "background-image": "url(" + src + ")",
            "background-size": "cover",
            "background-position": "center",
            "background-repeat": "no-repeat",
            display: "block",
        });

        el.hide();
    });

    /*=====================
    02. Shop Page Grid Setting Js
    ==========================*/
    $(".grid-option li").on("click", function () {
        $(this).addClass('active').siblings().removeClass('active');
    });
    $(".grid-option .grid-btn").on("click", function () {
        $(".product-list-section").removeClass("list-style");
    });
    $(".grid-option .list-btn").on("click", function () {
        $(".product-list-section").addClass("list-style");
    });
    /*
    $('.three-grid').on('click', function (e) {
        $(".product-list-section").removeClass("row-cols-xxl-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 list-style").addClass("row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2");
    });
    $('.grid-btn').on('click', function (e) {
        $(".product-list-section").removeClass("row-cols-xxl-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 list-style").addClass("row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2");
    });
    $('.five-grid').on('click', function (e) {
        $(".product-list-section").removeClass("list-style").addClass("row-cols-xxl-5 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2");
    });
    */
    var contentwidth = $(window).width();
    if (contentwidth < "1199") {
        $(".grid-options ul .grid-btn").addClass("active");
    }
    if (contentwidth < "1399") {
        $(".grid-options ul .three-grid").addClass("active");
    }
})(jQuery);

/*=====================
    03. Footer function js
==========================*/
var contentwidth = $(window).width();
if (contentwidth < "576") {
    $(".footer-title h4").append(
        '<span class="according-menu float-end"><i class="fa-solid fa-angle-down"></i></span>'
    );
    $(".footer-title").on("click", function () {
        $(".footer-title")
            .removeClass("active")
            .find("span")
            .replaceWith(
                '<span class="according-menu float-end"><i class="fa-solid fa-angle-down"></i></span>'
            );
        $(".footer-contact, .footer-contain").slideUp("normal");
        if ($(this).next().is(":hidden") == true) {
            $(this).addClass("active");
            $(this)
                .find("span")
                .replaceWith(
                    '<span class="according-menu float-end"><i class="fas fa-chevron-up"></i></span>'
                );
            $(this).next().slideDown("normal");
        } else {
            $(this)
                .find("span")
                .replaceWith(
                    '<span class="according-menu float-end"><i class="fa-solid fa-angle-down"></i></span>'
                );
        }
    });
    $(".footer-contact, .footer-contain").hide();
} else {
    $(".footer-contact, .footer-contain").show();
}

/*=====================
  04. mobile menu active class js
   ==========================*/
$(document).ready(function () {
    $('.mobile-menu ul li a').click(function () {
        $('li a').removeClass("active");
        $(this).addClass("active");
    });
});

/*=====================
  05. btn-cart open close js
   ==========================*/
$(document).ready(function () {
    $('.button-item').on("click", function () {
        $('.item-section').addClass("active");
    });

    $('.close-button').on("click", function () {
        $('.item-section').removeClass("active");
    });

    $('.btn-cart').on("click", function () {
        setTimeout(function () {
            $('.item-section').addClass("active")
        }, 1500);
        setTimeout(function () {
            $('.item-section').removeClass('active');
        }, 5000);
    });
});

/*=====================
  06. quantity js
   ==========================*/
$('.qty-box .quantity-right-plus').on('click', function () {
    var $qty = $(this).parents(".qty-box").find(".input-number");
    var currentVal = parseInt($qty.val(), 10);
    if (!isNaN(currentVal)) {
        $qty.val(currentVal + 0);
    }
});
$('.qty-box .quantity-left-minus').on('click', function () {
    var $qty = $(this).parents(".qty-box").find(".input-number");
    var currentVal = parseInt($qty.val(), 10);
    if (!isNaN(currentVal) && currentVal > 0) {
        $qty.val(currentVal - 0);
    }
});

/*=====================
  07. Tap to Top js
   ==========================*/
$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('.back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });
});

/*=====================
   08. User Dashboard Left Sidebar Show Js
   ==========================*/
$(".left-dashboard-show").click(function () {
    $(".bg-overlay, .dashboard-left-sidebar").addClass("show");
});
$(".close-button, .bg-overlay, .user-nav-pills .nav-item .nav-link").click(function () {
    $(".bg-overlay, .dashboard-left-sidebar").removeClass("show");
});

/*=====================
   09. Tooltip Js
   ==========================*/
   /*
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
//    })
*/

/*=====================
   10. Cookie Bar Js
   ==========================*/
$(".ok-button").click(function () {
    $(".cookie-bar-box").addClass("hide");
});

/*=====================
   11. Image To Background Js
   ==========================*/
$(".bg-top").parent().addClass("b-top");
$(".bg-bottom").parent().addClass("b-bottom");
$(".bg-center").parent().addClass("b-center");
$(".bg-left").parent().addClass("b-left");
$(".bg-right").parent().addClass("b-right");
$(".bg_size_content").parent().addClass("b_size_content");
$(".bg-img").parent().addClass("bg-size");
$(".bg-img.blur-up").parent().addClass("blur-up lazyload");
$(".bg-img").each(function () {
    var el = $(this),
        src = el.attr("src"),
        parent = el.parent();

    parent.css({
        "background-image": "url(" + src + ")",
        "background-size": "cover",
        "background-position": "center",
        "background-repeat": "no-repeat",
        display: "block",
    });

    el.hide();
});

/*=====================
   12. search box function Js
   ==========================*/
$(".search-box").on("click", function () {
    $(this).closest(".rightside-box").find(".search-full").addClass("open");
});
$(window).on("load resize", function () {
    // open searchbox
    $(".search-type").on("click", function () {
        $(this).parents(".search-full").addClass("show");
    });

    // close seach
    $(".close-search").on("click", function () {
        $(this).closest(".rightside-box").find(".search-full").removeClass("open");
    });
});

/*=====================
   13. Wishlist Js
   ==========================*/
$(".notifi-wishlist").on("click", function () {
    $.notify({
        icon: "fa fa-check",
        title: "Success!",
        message: "Item Successfully added in wishlist",
    }, {
        element: "body",
        position: null,
        type: "info",
        allow_dismiss: true,
        newest_on_top: false,
        showProgressbar: true,
        placement: {
            from: "top",
            align: "right",
        },
        offset: 20,
        spacing: 10,
        z_index: 1031,
        delay: 5000,
        animate: {
            enter: "animated fadeInDown",
            exit: "animated fadeOutUp",
        },
        icon_type: "class",
        template: '<div data-notify="container" class="col-xxl-3 col-lg-5 col-md-6 col-sm-7 col-12 alert alert-{0}" role="alert">' +
            '<button type="button" aria-hidden="true" class="btn-close" data-notify="dismiss"></button>' +
            '<span data-notify="icon"></span> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-info progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            "</div>" +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            "</div>",
    });
});

/*=====================
   14. Loader Js
   ==========================*/
// const loaderEl = document.getElementsByClassName("fullpage-loader")[0];
// document.addEventListener("readystatechange", () => {
//     const readyState = "complete";
//     if (document.readyState == readyState) {
//         loaderEl.classList.add("fullpage-loader--invisible");

//         setTimeout(() => {
//             loaderEl.parentNode.removeChild(loaderEl);
//         }, 100);
//     }
    
// });

/*=====================
    15. header Dropdown Js
 ==========================*/
$(".dropdown-menu li a").on('click', function () {
    var a = $(this).closest("a");
    var getSampling = a.text();
    var getImage = a.find('img').attr('src');

    // console.log("src path", getImage);
    $(this).closest(".dropdown-menu").prev('.dropdown-toggle').find('span').text(getSampling);
    $(this).closest(".dropdown-menu").prev('.dropdown-toggle').find('img').attr("src", getImage);
});

/*=====================
   16. Add to Cart Show Js
   ==========================*/
$(".addCart").click(function () {
    $(".add-cart-box").addClass("show");
    setTimeout(function () {
        $(".add-cart-box").removeClass("show");
    }, 5000);
});
$(".add-cart-box .btn-close").click(function () {
    $(".add-cart-box").removeClass("show");
});

/*=====================
   17. active class Js
   ==========================*/
$(".product-packege .select-packege li a").click(function () {
    $("li a").removeClass("active");
    $(this).addClass("active");
});

/*=====================
   18. Scroll down header fix js
   ==========================*/
$(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
        $('header').addClass('active')
    } else {
        $('header').removeClass('active')
    }
});

/*=====================
   19. setting-option open js
   ==========================*/
$(".theme-option .setting-box .setting-button").click(function () {
    $(".theme-setting-2").toggleClass("active");
    $(this).find("i").toggleClass("fa-xmark");
});

/*=====================
   20. user-dashboard profile change js
   ==========================*/
   /*
function readURL(uploader) {
    $('.update_img').attr('src',
        window.URL.createObjectURL(uploader.files[0]));
};
*/

/*=====================
   21. Wishlist box remove js
   ==========================*/
$(".close_button").click(function () {
    $(this).closest(".product-box-contain").fadeOut("slow", function () {
        $(this).closest(".product-box-contain").remove();
    });
});

/*=====================
   22. Category Box js
   ==========================*/
$(".mobile-category").click(function () {
    $(".bg-overlay, .category-dropdown").addClass("show");
});
$(".close-button, .bg-overlay").click(function () {
    $(".bg-overlay, .category-dropdown").removeClass("show");
});

/*=====================
   23. remove notication bar js
   ==========================*/
$(".close-notification").click(function () {
    $(".header-notification").addClass("remove");
});

/*=====================
    24. category box js
==========================*/
/*
var contentwidth = $(window).width();
if (contentwidth < "767") {
    $(".onhover-category-list .category-name").append('<span class="according-menu">+</span>');
    $(".category-name").on("click", function () {
        $(".category-name")
            .removeClass("active")
            .find("span")
            .replaceWith('<span class="according-menu">+</span>');
        $(".onhover-category-list .onhover-category-box").slideUp("normal");
        if ($(this).next().is(":hidden") == true) {
            $(this).addClass("active");
            $(this)
                .find("span")
                .replaceWith('<span class="according-menu">-</span>');
            $(this).next().slideDown("normal");
        } else {
            $(this)
                .find("span")
                .replaceWith('<span class="according-menu">+</span>');
        }
    });
    $(".accordion-box").hide();
}
*/
/*=====================
   25. Sidebar Hide & Show Js
   ==========================*/
$(".navbar-toggler-icon-2").click(function () {
    $(".bg-overlay, .sidebar-col").addClass("show");
});
$(".bg-overlay").click(function () {
    $(".bg-overlay, .sidebar-col").removeClass("show");
});
  }, 
  unmounted() {
    // Remove the resize event listener when the component is unmounted
    window.removeEventListener('resize', this.handleCategoryList);
  },
  
};
</script>


<template>

<!-- Loader Start -->
    <!-- <div class="fullpage-loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div> -->
    <!-- Loader End -->



    <header-nav/>
    <mobilefix-menu/>
    <RouterView/> 
    
    <footer-view/>


     

     <!-- Cookie Bar Box Start -->
     <!-- <div class="cookie-bar-box">
        <div class="cookie-box">
            <div class="cookie-image">
                <img src="./assets/images/cookie-bar.png" class="blur-up lazyload" alt="">
                <h2>Cookies!</h2>
            </div>

            <div class="cookie-contain">
                <h5 class="text-content">We use cookies to make your experience better</h5>
            </div>
        </div>

        <div class="button-group">
            <button class="btn privacy-button">Privacy Policy</button>
            <button class="btn ok-button">OK</button>
        </div>
    </div> -->
    <!-- Cookie Bar Box End -->

     <!-- Deal Box Modal Start -->
     <div class="modal fade theme-modal deal-modal" id="deal-box" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h5 class="modal-title w-100" id="deal_today">Deal Today</h5>
                        <p class="mt-1 text-content">Recommended deals for you.</p>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="deal-offer-box">
                        <ul class="deal-offer-list">
                            <li class="list-1">
                                <div class="deal-offer-contain">
                                    <a href="shop-left-sidebar.html" class="deal-image">
                                        <img src="./assets/images/vegetable/product/10.png" class="blur-up lazyload"
                                            alt="">
                                    </a>

                                    <a href="shop-left-sidebar.html" class="deal-contain">
                                        <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                        <h6>$52.57 <del>57.62</del> <span>500 G</span></h6>
                                    </a>
                                </div>
                            </li>

                            <li class="list-2">
                                <div class="deal-offer-contain">
                                    <a href="shop-left-sidebar.html" class="deal-image">
                                        <img src="./assets/images/vegetable/product/11.png" class="blur-up lazyload"
                                            alt="">
                                    </a>

                                    <a href="shop-left-sidebar.html" class="deal-contain">
                                        <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                        <h6>$52.57 <del>57.62</del> <span>500 G</span></h6>
                                    </a>
                                </div>
                            </li>

                            <li class="list-3">
                                <div class="deal-offer-contain">
                                    <a href="shop-left-sidebar.html" class="deal-image">
                                        <img src="./assets/images/vegetable/product/12.png" class="blur-up lazyload"
                                            alt="">
                                    </a>

                                    <a href="shop-left-sidebar.html" class="deal-contain">
                                        <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                        <h6>$52.57 <del>57.62</del> <span>500 G</span></h6>
                                    </a>
                                </div>
                            </li>

                            <li class="list-1">
                                <div class="deal-offer-contain">
                                    <a href="shop-left-sidebar.html" class="deal-image">
                                        <img src="./assets/images/vegetable/product/13.png" class="blur-up lazyload"
                                            alt="">
                                    </a>

                                    <a href="shop-left-sidebar.html" class="deal-contain">
                                        <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                        <h6>$52.57 <del>57.62</del> <span>500 G</span></h6>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Deal Box Modal End -->

    <!-- Tap to top start -->
    <div class="theme-option">
        <div class="back-to-top">
            <a id="back-to-top" aria-label="Go to Top">
                <i class="fas fa-chevron-up"></i>
            </a>
        </div>
    </div>
    <!-- Tap to top end -->

    <!-- Bg overlay Start -->
    <div class="bg-overlay"></div>
    <!-- Bg overlay End -->

    <!-- Bg overlay Start -->
    <div class="bg-overlay"></div>
    <!-- Bg overlay End -->

</template>



<style scoped>
    
</style>